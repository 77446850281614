@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");

.bg-unset {
  background-color: unset !important;
}

.subscribe-wrapper {
  box-sizing: border-box!important;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

.slider-wrapper {
  width: 760px;
  margin: auto;
}

.slick-slide {
  text-align: center;
  position: relative;
}

.slick-slide:focus {
  outline: none;
}

.slick-slide-title {
  text-transform: capitalize;
}

.slick-slide-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
}

.slick-slide-label {
  color: #fff;
  padding: 10px;
  position: absolute;
  left: 0px;
  font-size: 1.5em;
  bottom: 0px;
  width: 100%;
}

.slick-prev:before,
.slick-next:before {
  color: #777777;
}
